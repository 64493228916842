<template>
  <div class="white--text">
    <v-img class="poster" :src="data.bg_picture">
      <div class="mask">
        <v-btn icon color="white" to="/">
          <img class="arrow-icon" src="@/assets/icon/prev.svg" alt="prev" />
        </v-btn>
      </div>
    </v-img>
    <div class="content">
      <div class="d-flex justify-space-between align-end mb-7">
        <div style="max-width: 500px" class="text-break">
          <span class="f40 pr-2 font-krona">{{ data.title }}</span>
          <span class="f18">({{ data.release_year }})</span>
        </div>
        <v-btn class="btn" depressed color="error" height="48" width="180"><v-icon class="pr-3">mdi-play</v-icon> Watch trailer</v-btn>
      </div>
      <div class="d-flex justify-space-between mb-12">
        <div style="max-width: 500px" class="pr-2">
          <div class="mb-4">{{ data.description }}</div>
          <div class="d-flex flex-wrap align-center">
            <div class="d-flex flex-wrap" style="max-width: 300px">
              <div v-for="(i, c) in data.genres" :key="i.id" class="font-weight-medium text-no-wrap">
                {{ i.title }}<span v-if="c != data.genres.length - 1" class="pr-1">,</span>
              </div>
            </div>
            <div class="d-flex">
              <v-divider style="height: 10px" vertical class="white my-auto mx-5"></v-divider>
              <div>{{ data.mpa_rating }}</div>
              <v-divider style="height: 10px" vertical class="white my-auto mx-5"></v-divider>
            </div>
            <div>{{ time(data.duration) }}</div>
          </div>
        </div>
        <div class="rating">
          <div class="font-weight-medium">iMDb rating</div>
          <div>
            <img height="20" src="@/assets/icon/star.svg" alt="star" />
            <span class="f28" v-if="data.imdb_rating"> {{ data.imdb_rating.toFixed(1) }}</span>
            /10
          </div>
        </div>
      </div>
      <div class="mb-4">
        <div>
          Director:
          <span v-for="(i, c) in data.directors" :key="i.id" class="font-weight-medium">
            <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
          >
        </div>
        <div>
          Writers:
          <span v-for="(i, c) in data.writers" :key="i.id" class="font-weight-medium">
            <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
          >
        </div>
        <div>
          Stars:
          <span v-for="(i, c) in data.stars" :key="i.id" class="font-weight-medium">
            <span v-if="c != 0">,</span> {{ i.first_name }} {{ i.last_name }}</span
          >
        </div>
      </div>
      <div class="reting-mobile">
        <div>
          <div class="font-weight-medium">iMDb rating</div>
          <div>
            <img height="20" src="@/assets/icon/star.svg" alt="star" />
            <span class="f28" v-if="data.imdb_rating"> {{ data.imdb_rating.toFixed(1) }}</span>
            /10
          </div>
        </div>
        <v-btn depressed color="error" height="48" width="180"><v-icon class="pr-3">mdi-play</v-icon> Watch trailer</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  mounted() {
    this.$store.dispatch('getMoviesItem');
  },
  methods: {
    time(time) {
      if (time < 60) {
        return `${time} m`;
      } else {
        return `${Math.floor(time / 60)} h ${time % 60} m`;
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.movieItem;
    },
  },
  destroyed() {
    this.$store.dispatch('setMoviesList', { results: [] });
    this.$store.dispatch('setMoviesItem', {});
  },
};
</script>

<style scoped>
.poster {
  height: 700px;
}
.mask {
  background: linear-gradient(180deg, rgba(22, 22, 22, 0) 47.99%, #161616 100%);
  height: 100%;
  padding: 36px 30px 0;
}
.arrow-icon {
  height: 36px;
  width: 36px;
}
.content {
  margin-top: 20px;
  padding: 0 30px;
}
.rating {
  min-width: 97px;
}
.reting-mobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .poster {
    height: 500px;
  }
  .mask {
    background: linear-gradient(180deg, rgba(22, 22, 22, 0) 47.99%, #161616 100%);
    height: 100%;
    padding: 50px 24px 0;
  }
  .arrow-icon {
    height: 28px;
    width: 28px;
  }
  .content {
    position: absolute;
    top: 340px;
    margin-top: 0;
    padding: 0 24px;
  }
  .btn,
  .rating {
    display: none;
  }
  .reting-mobile {
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
